
import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';



export default function Contact ()
{
    return (
            <> 
            
                <Head />


                <div className='floatingCryptocoins'>
                   
                </div>



                    <section>

                        <div className='contactContainer'>

                            <div className='contactContainerContent'>

                                <div className='contactContainerContent1'>
                                    <h2>Contact Us.</h2>

<br />
<br />
                                        <div className='contactForm'>
                                            {/* contact form start */}

                                                <div className="row justify-content-center">
                                                        <div className="col-lg-12">
                                                        <form action="#" method="post" role="form" className="php ">
                                                        
                                                            
                                                            <div className="row">
                                                                <div className="col-md-6 form-group">
                                                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name"
                                                                        required />
                                                                </div>
                                                                <div className="col-md-6 form-group mt-3 mt-md-0">
                                                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email"
                                                                        required />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mt-3">
                                                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject"
                                                                    required />
                                                            </div>
                                                            <div className="form-group mt-3">
                                                                <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                                                            </div>
                                            
                                                            <div className="mt-3">
                                                                <button className="cmn-btn w-100 buttForm" type="submit">Send Message</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                
                                                {/* contact form end */}


                                        </div>



                                        <div className='otherContactDetails'>

                                                    <div className='otherContactDetailsCard'>

                                                            <center>
                                                                <div className='contImage1'>
                                                                    <img src="cont/bkloc.svg" alt="Office Address Image" width={80} height={80} />
                                                                </div>

                                                                <h3>Address: </h3>

                                                                <p>
                                                                1201 Louisiana St
FL 35
Houston, Texas 
United States
                                                                </p>

                                                            </center>

                                                    </div>
                                                    <div className='otherContactDetailsCard'>

                                                            <center>
                                                                <div className='contImage1'>
                                                                <img src="cont/bkemail.svg" alt="Office Address Image" width={80} height={80} />
                                                                </div>

                                                                <h3>Email: </h3>

                                                                <p>
                                                                support@arbitragecreditunion.com
                                                                </p>

                                                            </center>


                                                    </div>
                                                    {/* <div className='otherContactDetailsCard'>

                                                            <center>
                                                                <div className='contImage1'>
                                                                <img src="cont/bkphone.svg" alt="Office Address Image" width={80} height={80} />
                                                                </div>

                                                                <h3>Phone: </h3>

                                                                <p>
                                                                    Nil
                                                                </p>

                                                            </center>


                                                    </div> */}

                                                    <p className="clearx"></p>
                                        </div>








                                </div>
                                

                           
                            </div>

                        </div>




                    </section>













                
                <Foot />
            
            
            
            
             </>

    );


}
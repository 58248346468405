

// import $ from "jquery";
import {Link } from "react-router-dom";


import {useEffect} from 'react';
import axios from 'axios';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

// toast notification
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logo from "../images/logo.png";

export default function DashNav(){

    const navigateRoutes = useNavigate();


    useEffect(() => {

       const checkIfUserTIsLogin = Cookies.get('isUserOnline');
        

       if(checkIfUserTIsLogin){
        
       


       }else{
        
        navigateRoutes('/login', { replace: true });

       }
       


    }, []);



    const userLogout = () => {

        localStorage.clear();
        Cookies.remove("isUserOnline");
        navigateRoutes('/login', { replace: true });

    }
    




    return(

        <>
        
        {/* dash header */}
  
        
                {/* side nav */}
                <section className="sideNavContainer"> 

                    {/* logo container */}
                    <div className="logoContainer">
                         {/* <h2 className="logoText">Bank Logo</h2> */}
                         <Link to="/dashboard">
                         <img src={logo} width={100} style={{ 
                                            position:"relative",
                                            top: -10,
                                         }}  />
                         </Link>
                    </div>

                {/* nav container */}
                <div className="navContainer">
                    <nav>
                        <ul>

                        <Link to="/dashboard">
                            <li style={{ backgroundColor: "#092980", color: "white", }}>
                                <Link to="/dashboard">
                                    <i class="bi bi-house" style={{  color: "white", }}></i>
                                    <span className="navText" style={{  color: "white", }}>Dashboard</span>
                                </Link>
                               
                            </li>
                            </Link>

                            {/* <Link to="/dashasset">
                            <li>
                                <Link to="/dashasset">
                                <i class="bi bi-wallet2"></i>
                                    <span className="navText">Assets</span>
                               </Link>
                               
                            </li>
                            </Link> */}
                           

                           
                            <Link to="/dashdeposit">
                            <li>
                                <Link to="/dashdeposit">
                                <i class="bi bi-cash"></i>
                                    <span className="navText">Deposit Funds</span>
                               </Link>
                               
                            </li>
                            </Link>



                            <Link to="/dashwithdraw">
                            <li>
                                <Link to="/dashwithdraw">
                                <i class="bi bi-cash-coin"></i>
                                    <span className="navText">Transfer Funds</span>
                               </Link>
                               
                            </li>
                            </Link>



                           {/* <Link to="/dashreferral">
                            <li>
                                <Link to="/dashreferral">
                                <i class="bi bi-people"></i>
                                    <span className="navText">Referral</span>
                               </Link>
                               
                            </li>
                            </Link> */}
                           
                            

                            <Link to="/withdrawviacrypto">
                            <li>
                                <Link to="/withdrawviacrypto">
                                <i class="bi bi-file-earmark-arrow-up"></i>
                                    <span className="navText">Withdraw Via Crypto</span>
                               </Link>
                               
                            </li>
                            </Link>

                            {/* <Link to="/dashkyc">
                            <li>
                                <Link to="/dashkyc">
                                <i class="bi bi-file-earmark-arrow-up"></i>
                                    <span className="navText">KYC</span>
                               </Link>
                               
                            </li>
                            </Link>
                            */}


                            <Link to="/dashcard">
                            <li>
                                <Link to="/dashcard">
                                <i class="bi bi-credit-card"></i>
                                    <span className="navText">Debit Card</span>
                               </Link>
                               
                            </li>
                            </Link>
                           


                            {/* <Link to="/dashtransaction">
                            <li>
                                <Link to="/dashtransaction">
                                <i class="bi bi-clock-history"></i>
                                    <span className="navText">History</span>
                               </Link>
                               
                            </li>
                            </Link> */}


                            {/* <Link to="/dashgroupmsg">
                            <li>
                                <Link to="/dashgroupmsg">
                               
                                <i class="bi bi-chat-dots-fill "  ></i>
                                    <span className="navText">Support</span>
                               </Link>
                               
                            </li>
                            </Link> */}

                            <Link to="/dashsettings">
                            <li>
                                <Link to="/dashsettings">
                                {/* <i class="bi bi-clock-history"></i> */}
                                <i class="bi bi-gear "></i>
                                    <span className="navText">Settings</span>
                               </Link>
                               
                            </li>
                            </Link>

                            <Link to="/dashprofile">
                            <li>
                                <Link to="/dashprofile">
                               
                                <i class="bi bi-person-circle " ></i>
                                    <span className="navText">Profile</span>
                               </Link>
                               
                            </li>
                            </Link>


                            <Link to="/applyloan">
                            <li>
                                <Link to="/applyloan">
                               
                                <i class="bi bi-activity"></i>
                                    <span className="navText">Loan</span>
                               </Link>
                               
                            </li>
                            </Link>



                           {/* <Link to="/dashactivetrades">
                            <li>
                                <Link to="/dashactivetrades">
                                <i class="bi bi-activity"></i>
                                    <span className="navText">Active Trades</span>
                               </Link>
                               
                            </li>
                            </Link> */}


                           
                            <li className="logoutUser" onClick={userLogout} style={{ backgroundColor: "red" }}>
                                {/* <Link to="/dashboard"> */}
                                <i class="bi bi-box-arrow-right" style={{ color: "white" }}></i>
                                    <span className="navText" style={{ color: "white" }}>Logout</span>
                               {/* </Link> */}
                               
                            </li>
                           




                        </ul>

                    </nav>

                </div>


                </section>





                
        
        
        
        
        
        
        </>

    );




}